<template>
    <span >
        <v-btn :disabled="disabledButtons || options.page <= 1" icon @click="$emit('prev-page')">
            <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <span v-if="(!$vuetify.breakpoint.smAndDown || !showDetailsOfSmallPage ) && paginations.count" class="text-body-2" :title="`Сторінка: ${options.page} з ${lastPage} ( Елементи: ${paginations.start_index}-${paginations.end_index} з ${paginations.count} )`">{{ options.page }} / {{ lastPage }} ( {{ paginations.count }} )</span> 
        <v-btn :disabled="disabledButtons || paginations.count == paginations.end_index" icon @click="$emit('next-page')">
            <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
    </span>
</template>

<script>

export default {
    name: "navigations-buttons",
    props: ["disabledButtons", "showDetailsOfSmallPage", "options", "paginations"],
    data: () => ({
        page: 0
    }),
    mounted(){
        //console.log("mounted navigations-buttons")
    },
    computed: {
        lastPage(){
          let rawLastPage = this.paginations.count / this.options.itemsPerPage
          return rawLastPage && Math.ceil(rawLastPage) || 1
        }
    },
    methods: {
        //
    }
}
</script>
